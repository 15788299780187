.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.rdg {
  --rdg-background-color: #fff !important;
  --rdg-header-background-color: #fff !important;
  --rdg-row-hover-background-color: #fff !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

.text-right {
  text-align: right;
}
