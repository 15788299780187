.Snackbar {
    position: fixed;
    z-index: 8;
    width: 100%;
    min-height: 50px;
    left: 0;
    bottom: 0;
    overflow: hidden;
    background-color: var(--os-c-snackbar);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    box-sizing: border-box;

    &Error {
        background-color: var(--os-c-red);
    }

    &Success {
        background-color: var(--os-c-success);
    }
}
