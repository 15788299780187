.View57 {
    &Search {
        --breakpoint-xl: var(--os-breakpoint-xl);
        --border-color: var(--os-c-gray-light);
        --color: var(--os-c-primary-light);
        --color-text-help: var(--os-c-text-help);

        max-width: 98%;
        margin: 0 auto 60px;
        border: 1px solid var(--border-color);
        background-color: white;
        box-sizing: border-box;
        box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        min-height: 50px;
        padding: 1rem;
        margin-bottom: 1rem;
        width: 100%;

        .col {
            padding: 0 0.5rem;
        }

        @media (max-width: 1240px) {
            margin-left: 0;
            margin-right: 0;
            border-radius: 0;
            box-shadow: none;
            border: 0 none;
            margin-bottom: 1rem;

            .row-6 {
                flex-direction: column;

                .col {
                    width: 100%;
                    padding: 0;
                    margin: 0.5rem 0 0;

                    .empty {
                        display: none;
                    }

                    .empty + button {
                        margin-top: 0.5rem;
                    }
                }
            }
        }

        @media (max-width: 960px) {
            padding: 0;
        }

        &Nav {
            text-align: center;
            margin: 0.75rem 0.75rem 0;

            ul,
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            li {
                margin: 0;
                list-style: none;
                display: inline-block;
                margin: 0 5px;
                font-weight: bold;
                opacity: 0.3;
                cursor: pointer;
                font-size: 15px;
                padding: 5px;
                line-height: 1;

                &:hover {
                    text-decoration: underline;
                }

                &.Arrow,
                &.Active {
                    opacity: 1;
                }
            }
        }

        &Actions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1rem 0.5rem;
        }
    }

    &Table {
        border-radius: 0.5rem;
        border: 0 none;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.08);
        height: 55vh;

        .rdg-header-row {
            .rdg-cell {
                background-color: var(--os-c-complement);
                color: var(--os-c-text);
                text-align: center;
            }
        }

        .rdg-row {
            .rdg-cell {
                color: var(--os-c-text);
                cursor: pointer;

                &[aria-colindex='7'],
                &[aria-colindex='8'],
                &[aria-colindex='9'],
                &[aria-colindex='10'],
                &[aria-colindex='11'],
                &[aria-colindex='12'],
                &[aria-colindex='13'],
                &[aria-colindex='14'],
                &[aria-colindex='15'],
                &[aria-colindex='16'],
                &[aria-colindex='17'],
                &[aria-colindex='18'],
                &[aria-colindex='19'],
                &[aria-colindex='20'],
                &[aria-colindex='21'],
                &[aria-colindex='22'],
                &[aria-colindex='23'],
                &[aria-colindex='24'],
                &[aria-colindex='25'],
                &[aria-colindex='26'],
                &[aria-colindex='27'] {
                    display: flex;
                    align-items: center;
                    justify-content: right;

                    a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: right;
                    }
                }

                &[aria-colindex='3'] {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &[aria-selected='true'] {
                    box-shadow: inset 0 0 0 2px #cdcbc9;
                }
            }
        }
    }

    &Body {
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 1rem 1rem;
        min-height: 50px;
        width: 100%;

        &Cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 21vw));
            grid-gap: 2rem;
        }

        @media (max-width: 1240px) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 1rem;
        }

        @media (max-width: 960px) {
            padding: 1rem 0 0;

            &Cards {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media (max-width: 540px) {
            &Cards {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    &Pdf {
        width: 24px;
    }

    @media (max-width: 1240px) {
        &Search {
            max-width: 100%;
        }
    }
}
