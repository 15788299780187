.Table {
    &Nav {
        text-align: center;
        margin: 0.75rem 0 3rem;

        ul,
        li {
            margin: 0;
            list-style: none;
        }

        li {
            margin: 0;
            list-style: none;
            display: inline-block;
            margin: 0 5px;
            font-weight: bold;
            opacity: 0.3;
            cursor: pointer;
            font-size: 15px;
            padding: 5px;
            line-height: 1;

            &:hover {
                text-decoration: underline;
            }

            &.Arrow,
            &.Active {
                opacity: 1;
            }
        }
    }

    &Actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1rem 0.5rem;
    }

    &Total {
        font-size: 20px;
        padding-right: 10px;
        line-height: 1;
    }

    @media (max-width: 1240px) {
        max-width: 100%;
        margin: 0 1rem 1.5rem;
        overflow-y: auto;

        &Total {
            font-size: 18px;
        }
    }
}
