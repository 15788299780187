.App {
    // datepicker
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 50%;
        background-color: var(--os-c-primary);
        color: white;
    }

    .react-datepicker {
        font-family: var(--os-f-family);
        color: var(--os-c-text);
        border: 1px solid var(--os-c-gray);
    }

    .react-datepicker__header {
        background-color: white;
        border-bottom: 1px solid var(--os-c-gray);
    }

    .react-datepicker__navigation-icon::before,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-color: var(--os-c-primary);
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 50%;
        background-color: var(--os-c-primary-light-2);
        color: var(--os-c-text);
    }

    .react-datepicker__day--keyboard-selected {
        border-radius: 50%;
        background-color: var(--os-c-secondary);
        color: #fff;
    }

    .react-datepicker-popper[data-placement^='bottom']
        .react-datepicker__triangle::before {
        border-bottom-color: var(--os-c-gray);
    }

    .react-datepicker-popper[data-placement^='bottom']
        .react-datepicker__triangle::after {
        border-bottom-color: white;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
}
