.Footer {
    display: flex;
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    background-color: white;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 0.75rem;
    border-top: 0.5px solid var(--os-c-gray);
}
