.Card {
    --border-color: var(--os-c-gray-light);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    border: 1px solid var(--os-c-gray-dark);
    box-sizing: border-box;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.5rem 0;
    position: relative;

    &Title {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        line-height: 1;
        padding: 0.5rem 0.25rem;
        text-align: center;
        font-size: 1.15rem;

        .Status1 {
            background-color: #4caf50;
            color: white;
        }

        .Status2 {
            background-color: rgba(249, 70, 70, 0.5);
        }

        .Status2 {
            background-color: rgba(250, 175, 64, 0.5);
        }
    }

    &LeftTop {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: start;
        left: 0.5rem;
        top: 0.25rem;
        width: 90px;
        text-align: left;
        font-size: 1.15rem;

        img {
            width: 1.25rem;
            margin-right: 0.25rem;
        }
    }

    &RightTop {
        width: 90px;
        text-align: right;
        position: absolute;
        display: inline-flex;
        justify-content: end;
        align-items: center;
        right: 0.5rem;
        top: 0.25rem;
        font-size: 1.15rem;

        img {
            width: 1.25rem;
            margin-right: 0.25rem;
        }
    }

    &LeftBottom {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: start;
        left: 0.5rem;
        bottom: 0.5rem;
        width: 90px;
        text-align: left;
        font-size: 0.875rem;
        color: var(--os-c-primary);

        &Link {
            cursor: pointer;
            color: var(--os-c-primary);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &RightBottom {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: end;
        right: 0.5rem;
        bottom: 0.5rem;
        width: 90px;
        text-align: right;
        font-size: 1rem;

        img {
            width: 2rem;
            cursor: pointer;
        }
    }
}
