.helpText {
    --color-red: var(--os-c-red);

    padding: 5px 8px 0;
    font-size: 0.9em;
    text-align: left;

    &.invalid {
        color: var(--color-red);
    }

    &.valid {
        color: var(--os-c-secondary);
    }
}
