.Loader {
    position: fixed;
    z-index: 8;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
    background-color: rgba(white, 0.5);

    &Inner {
        z-index: 9;
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        color: var(--os-c-primary);
    }
}
