.View63 {
  &Search {
      --breakpoint-xl: var(--os-breakpoint-xl);
      --border-color: var(--os-c-gray-light);
      --color: var(--os-c-primary-light);
      --color-text-help: var(--os-c-text-help);

      max-width: 98%;
      margin: 0 auto 60px;
      border: 1px solid var(--border-color);
      background-color: white;
      box-sizing: border-box;
      box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      min-height: 50px;
      padding: 1rem;
      margin-bottom: 1rem;
      width: 100%;

      .col {
          padding: 0 0.5rem;

          .buttonPDF {
            display: flex;
            color: var(--os-c-text);
            background: transparent;
            border: none;
            gap: 15px;
            img {
                width: 27px;
            }
          }
      }

      @media (max-width: 1240px) {
          margin-left: 0;
          margin-right: 0;
          border-radius: 0;
          box-shadow: none;
          border: 0 none;
          margin-bottom: 1rem;

          .row-6 {
              flex-direction: column;

              .col {
                  width: 100%;
                  padding: 0;
                  margin: 0.5rem 0 0;

                  .empty {
                      display: none;
                  }

                  .empty + button {
                      margin-top: 0.5rem;
                  }
              }
          }
      }

      @media (max-width: 960px) {
          padding: 0;
      }

      &Nav {
          text-align: center;
          margin: 0.75rem 0.75rem 0;

          ul,
          li {
              margin: 0;
              padding: 0;
              list-style: none;
          }

          li {
              margin: 0;
              list-style: none;
              display: inline-block;
              margin: 0 5px;
              font-weight: bold;
              opacity: 0.3;
              cursor: pointer;
              font-size: 15px;
              padding: 5px;
              line-height: 1;

              &:hover {
                  text-decoration: underline;
              }

              &.Arrow,
              &.Active {
                  opacity: 1;
              }
          }
      }

      &Actions {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 1rem 0.5rem;
      }
  }

  &Table {
      border-radius: 0.5rem;
      border: 0 none;
      box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.08);
      height: 55vh;

      .rdg-header-row {
          .rdg-cell {
              background-color: var(--os-c-complement);
              color: var(--os-c-text);
              text-align: center;
          }
      }

      .rdg-row {
          .rdg-cell {
              color: var(--os-c-text);
              cursor: pointer;

              a {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
              }

              &[aria-selected='true'] {
                  box-shadow: inset 0 0 0 2px #cdcbc9;
              }

              &.colcenter {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
              }

              &.aut316, &.aut317 {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
              }
              &.aut316 {
                  background: #e4dfec;
              }

              &.aut317 {
                  background: #ebf1de;
              }
          }
      }
  }

  &Body {
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 1rem 1rem;
      min-height: 50px;
      width: 100%;

      &Cards {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(240px, 21vw));
          grid-gap: 2rem;
      }

      @media (max-width: 1240px) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 1rem;
      }

      @media (max-width: 960px) {
          padding: 1rem 0 0;

          &Cards {
              grid-template-columns: repeat(2, 1fr);
          }
      }

      @media (max-width: 540px) {
          &Cards {
              grid-template-columns: repeat(1, 1fr);
          }
      }

    #divToPrint {
        .logo {
            display: none;
            &__container {
                margin: auto;
                width: 300px;
                img {
                    margin: auto;
                }
            }
        }
    }
    #divToPrint.printer {
        .logo {
            display: flex;
            flex-direction: column;
            padding-top: 170px;
            &__container {
                margin: auto;
                width: 200px;
                margin-bottom: 25px;
                img {
                    margin: auto;
                }
            }
            h1 {
                margin: auto;
                font-size: 24px;
            }
        }
        .row-2 {
            width: 100% !important;
            flex-direction: column !important;
            .col {
                width: 75% !important;
                margin: 0 auto !important;
            }
        }
    }
    .chart-panel {
        padding: 20px 0;
        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
        &__item {
            padding: 20px;
            border: 1px solid #e8e8e8;
            height: 400px;
        }
        &__table {
            .tableResumen {
                display: grid;
                margin-top: 25px;
                border: 1px solid #8db4e2;

                &__header {
                    display: grid;
                    grid-template-columns: 25% 25% 25% 25%;
                    background: #8db4e2;

                    &.atendidos {
                        grid-template-columns: 75% 25%;
                    }

                    &__col {
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        color: #000;
                        font-weight: bold;
                        line-height: 20px;
                        padding: 10px 0;
                        border: 1px dashed #6b6b6b;
                        text-transform: uppercase;

                        @media (max-width: 540px) {
                            font-size: 10px;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                            line-height: 15px;
                        }
                    }
                }

                &__body, &__footer {
                    display: grid;
                    grid-template-columns: 25% 25% 25% 25%;

                    &.atendidos {
                        grid-template-columns: 75% 25%;
                    }

                    &__col {
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        color: #000;
                        border: 1px dashed #6b6b6b;
                        text-transform: uppercase;

                        @media (max-width: 540px) {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1240px) {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
        box-shadow: none;
        border: 0 none;
        margin-bottom: 1rem;

        .row-2 {
            flex-direction: column;

            .col {
                width: 100%;
                padding: 0;
                margin: 0.5rem 0 0;

                .empty {
                    display: none;
                }

                .empty + button {
                    margin-top: 0.5rem;
                }
            }
        }

        .apexcharts-toolbar {
            display: none;
        }
        .apexcharts-title-text {
            transform: translateX(-105px);
        }
    }
  }

  &Pdf {
      width: 24px;
  }

  @media (max-width: 1240px) {
      &Search {
          max-width: 100%;
      }
  }
}