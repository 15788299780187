.spinner {
    display: inline-block;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: spinner 1.8s infinite ease-in-out;
    font-size: 10px;
    margin: 0 auto;
    position: relative;
    // text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;

    &:before,
    &:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: spinner 1.8s infinite ease-in-out;
    }

    &secondary {
        color: var(--os-c-secondary);
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
    }

    &:before {
        left: -3.5em;
        animation-delay: -0.32s;
    }

    &:after {
        left: 3.5em;
    }
}

@keyframes spinner {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
