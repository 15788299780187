.ModalPhoto {
    position: fixed;
    overflow-x: fixed;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;

    &Overlay {
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        position: fixed;
        background-color: var(--os-c-complement);
        opacity: 0.8;
    }

    &Content {
        position: relative;
        z-index: 2;
        max-width: 96vw;
        margin: 0 auto;
        top: 2vh;
        box-sizing: border-box;
        padding: 20px;
        margin-top: 0;
        background-color: white;
        box-sizing: border-box;
        box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        height: 94vh;
    }

    &Derscription {
        margin: 0 auto;
        text-align: center;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        img {
            max-width: 90vw;
            max-height: 90vh;
        }
    }

    &Title {
        font-weight: bold;
        margin: 1rem 0;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        color: var(--os-c-primary);
    }

    &Actions {
        text-align: center;
    }

    &Close {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: var(--os-c-primary);
        border: 0 none;
        outline: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
    }

    &Next {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        margin-top: -18px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: var(--os-c-secondary);
        border: 0 none;
        outline: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
    }

    &Prev {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        margin-top: -18px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: var(--os-c-secondary);
        border: 0 none;
        outline: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
    }

    // animated

    &Enter {
        transition: 1ms ease-out;
    }

    &EnterFrom {
        transform: scale(0.95);
        opacity: 0;
    }

    &EnterTo {
        transform: scale(1);
        opacity: 1;
    }

    &Leave {
        transition: 75ms ease-out;
    }

    &LeaveFrom {
        transform: scale(1);
        opacity: 1;
    }

    &LeaveTo {
        transform: scale(0.95);
        opacity: 0;
    }
}
