.Header {
    --breakpoint-xl: var(--os-breakpoint-xl);
    --color-h: var(--os-c-secondary);
    --font-light: var(--os-f-family);
    width: 100%;

    &Inner {
        padding: 0 2.5rem;
        margin: 0 auto;
    }

    &Nav {
        padding: 1rem 0;
        width: 100%;
        display: flex;
    }

    &Logo {
        margin: 0;
        padding: 0;
        line-height: 1;
        display: inline-flex;
        align-items: center;

        img {
            height: 54px;
        }
    }

    &Items {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;

        &Menu {
            display: flex;
            align-items: center;
        }
    }

    &SubItem {
        display: inline-block;
        position: relative;
    }

    &SubItemMobile {
        display: none;
    }

    &Item {
        cursor: pointer;
        font-size: 1.235rem;
        color: var(--os-c-text);
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        display: inline-block;
        border-radius: 10px 10px 0 0;
        border: 1px solid transparent;
        border-bottom: 0 none;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;

        img {
            width: 0.75rem;
            transition: transform 0.25s ease-in-out;
        }

        &Open {
            border-color: var(--os-c-gray);
            img {
                transform: rotate(180deg);
            }
        }

        &Active {
            color: var(--os-c-primary);
            font-family: var(--os-f-family);
        }
    }

    &ItemMobile {
        cursor: pointer;
        font-size: 1.5rem;
        color: var(--os-c-text);
        text-transform: uppercase;
        padding: 0.5rem;
        display: inline-block;
        border-radius: 10px 10px 0 0;
        border: 1px solid transparent;
        border-bottom: 0 none;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;

        img {
            width: 0.75rem;
            transition: transform 0.25s ease-in-out;
        }

        &Active {
            color: var(--os-c-primary);
            font-family: var(--os-f-family);
        }
    }

    &Ul {
        background: #fff;
        border: 1px solid transparent;
        box-sizing: border-box;
        box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 0 0 10px 10px;
        overflow: hidden;
        outline: none;
        position: absolute;
        z-index: 2;
        min-width: 190px;
        width: 100%;
        min-height: 100px;
        max-height: 450px;
        overflow: auto;

        &Open {
            border-color: var(--os-c-gray);
        }
    }

    &Li {
        display: block;
        color: var(--os-c-text);
        font-size: 1rem;
        margin: 0;

        &:last-child a {
            border-bottom: 0 none;
        }

        a,
        > span {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            color: var(--os-c-text);
            padding: 0.75rem 1.25rem;
            font-family: var(--os-f-family);
            position: relative;
            z-index: 2;
            overflow: hidden;

            &:hover {
                color: white;
                background-color: var(--os-c-primary);
            }

            img {
                margin-right: 10px;
            }
        }

        &Highlight {
            color: white;
            background-color: var(--os-c-primary);

            a,
            > span {
                color: white;

                &.active {
                    color: white;
                }
            }
        }
    }

    &Icon {
        margin-left: 5px;

        &Hamb {
            width: 1.75rem !important;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &UlMobile {
        background: #fff;
        border-right: 1px solid transparent;
        box-sizing: border-box;
        box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
        outline: none;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        height: 100vh;
        width: 260px;
        padding: 1rem 0;
        overflow-y: auto;
        overflow-x: hidden;

        li {
            border-radius: 10px 10px 0 0;
        }

        &Open {
            border-color: var(--os-c-gray);
        }
    }

    &LiMobile {
        display: block;
        color: var(--os-c-text);
        font-size: 1.25rem;
        margin: 0;
        position: relative;

        &:last-child a {
            border-bottom: 0 none;
        }

        a,
        > span {
            display: flex;
            box-sizing: border-box;
            width: 98%;
            color: var(--os-c-text);
            padding: 0.75rem 0.75rem 0.75rem 1.25rem;
            font-family: var(--os-f-family);
            position: relative;
            z-index: 2;
            cursor: default;
        }

        &Ul {
            padding: 0 0.5rem 0 1.5rem !important;
            list-style: none;

            &Li {
                position: relative;
                font-size: 1.15rem;
                list-style: circle;
                margin-left: 1rem;
                a {
                    padding: 0.25rem;
                    color: var(--os-c-text);
                    cursor: pointer;

                    &:hover {
                        color: var(--os-c-primary);
                        text-decoration: underline;
                    }
                }
            }
        }

        &Cnt {
            padding: 1rem 1rem;
            line-height: 1;
            cursor: pointer;
        }

        .btnArrow {
            position: absolute;
            right: 1rem;
            top: 1.5rem;
            transition: transform 0.25s ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    @media (max-width: 1240px) {
        &Inner {
            padding: 0 1rem;
            margin: 0 auto;
        }

        &Item {
            font-size: 1.25rem;
        }

        &Logo {
            img {
                height: 32px;
            }
        }
    }

    @media (max-width: 960px) {
        &Inner {
            padding: 0 0.5rem;
            margin: 0 auto;
        }

        &SubItem {
            display: none;
        }

        &SubItemMobile {
            display: inline-flex;
        }
    }

    @media (max-width: 540px) {
        &Logo {
            img {
                height: 28px;
            }
        }
    }
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d2d0ce;
    border-radius: 6px;
    border: 8px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}