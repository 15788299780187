.Breadcrumb {
    background-color: var(--os-c-complement);
    font-size: 1.5rem;
    padding: 1rem 2.5rem 1rem 2.5rem;
    line-height: 1;
    min-height: 3.5rem;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    &.BreadcrumbIcon {
        padding-left: 4rem;
    }

    &BackLink {
        display: inline-flex;
        position: absolute;
        left: 1rem;
        top: 50%;
        margin-top: -22px;
        cursor: pointer;
        color: var(--os-c-text);

        svg,
        img {
            width: 45px;
        }
    }

    @media (max-width: 1240px) {
        padding: 1rem;
    }

    @media (max-width: 960px) {
        font-size: 1.25rem;
        padding: 1rem 0.5rem 1rem 0.5rem;

        &BackLink {
            svg,
            img {
                width: 40px;
            }
        }
    }
}
