.Disclosure {
    width: 100%;
    margin: 0 0 2rem;

    &Button {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.25rem;
        position: relative;
        display: flex;
        padding: 0 0 1.5rem;
        cursor: pointer;
        // background-color: white;

        &Text {
            display: inline-block;
            padding: 0 0.5rem 0 0;
            background-color: white;
        }

        &:before {
            content: '';
            display: block;
            height: 1px;
            position: absolute;
            left: 0;
            top: 40%;
            margin-top: -1px;
            width: 100%;
            z-index: -1;
            background-color: var(--os-c-complement);
        }

        &Img {
            height: 100%;
            background-color: white;
            padding-left: 0.5rem;
            display: inline-flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            margin-top: -0.25rem;

            img {
                transition: transform 0.25s ease-in-out;
            }

            img.open {
                transform: rotate(180deg);
            }
        }
    }

    &Panel {
    }

    // animated
    &Enter {
        transition: 100ms ease-out;
    }

    &EnterFrom {
        transform: scale(0.95);
        opacity: 0;
    }

    &EnterTo {
        transform: scale(1);
        opacity: 1;
    }

    &Leave {
        transition: 75ms ease-out;
    }

    &LeaveFrom {
        transform: scale(1);
        opacity: 1;
    }

    &LeaveTo {
        transform: scale(0.95);
        opacity: 0;
    }
}
