.row {
    display: flex;
}
.col {
    flex: 1;
}

.col-50 {
    flex-basis: 50%;
}

.col-3 {
    flex-basis: 33.33%;
}

.col-4 {
    flex-basis: 25%;
}

.col-6 {
    flex-basis: 16.66%;
}

@media (max-width: 400px) {
    .row {
        display: block;
    }

    .row > .col {
        padding: 0 !important;
    }
}

.row,
.row-2,
.row-3,
.row-4,
.row-6 {
    display: flex;
    justify-content: space-between;
}

.row .col {
    width: 50%;
}

.row-2 .col {
    width: 50%;
}

.row-3 .col {
    width: 33%;
}

.row-4 .col {
    width: 25%;
}

.row-6 .col {
    width: 16.66%;
}
