.input {
    --border-color: var(--os-c-gray);
    --border-color-accent: var(--os-c-gray-accent);
    --background-color: var(--os-c-light);
    --color: var(--os-c-text);
    --color-red: var(--os-c-red);
    --font: var(--os-f-family);

    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    font-size: 1rem;
    outline: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    appearance: none;
    user-select: none;
    position: relative;
    text-align: left;
    font-family: var(--font);

    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--color);

    &:hover,
    &:focus {
        --border-color: var(--border-color-accent);
    }

    &[disabled],
    &[disabled='disabled'] {
        --background-color: var(--os-c-gray-light);
        --color: var(--os-c-gray);

        &:hover,
        &:focus {
            --border-color: var(--os-c-gray);
            --color: var(--os-c-gray);
        }
    }

    &[readonly],
    &[readonly='readonly'] {
        --background-color: var(--os-c-complement);
        --color: var(--os-c-text);

        &:hover,
        &:focus {
            --background-color: var(--os-c-complement);
            --color: var(--os-c-text);
        }
    }

    &.invalid {
        border-color: var(--color-red);
    }
}
