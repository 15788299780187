.View69 {
  &Title {
    background: #f2f2f2;
    border: 1px solid #d2d2d2;
    padding: 15px;
    color: #353a3d;
    font-size: 16px;
    font-weight: bold;
  }
  &Search {
    --breakpoint-xl: var(--os-breakpoint-xl);
    --border-color: var(--os-c-gray-light);
    --color: var(--os-c-primary-light);
    --color-text-help: var(--os-c-text-help);

    max-width: 98%;
    margin: 0 auto 60px;
    border: 1px solid var(--border-color);
    background-color: white;
    box-sizing: border-box;
    box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    min-height: 50px;
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    .col {
      padding: 0 0.5rem;
    }

    @media (max-width: 1240px) {
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
      box-shadow: none;
      border: 0 none;
      margin-bottom: 1rem;

      .row-6 {
        flex-direction: column;

        .col {
          width: 100%;
          padding: 0;
          margin: 0.5rem 0 0;

          .empty {
            display: none;
          }

          .empty + button {
            margin-top: 0.5rem;
          }
        }
      }
    }

    @media (max-width: 960px) {
      padding: 0;
    }

    &Nav {
      text-align: center;
      margin: 0.75rem 0.75rem 0;

      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        margin: 0;
        list-style: none;
        display: inline-block;
        margin: 0 5px;
        font-weight: bold;
        opacity: 0.3;
        cursor: pointer;
        font-size: 15px;
        padding: 5px;
        line-height: 1;

        &:hover {
          text-decoration: underline;
        }

        &.Arrow,
        &.Active {
          opacity: 1;
        }
      }
    }

    &Actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1rem 0.5rem;

      &Total {
        display: flex;
        gap: 30px;
        justify-content: center;
        align-items: center;

        button {
          height: 25px;
          background: green;
          border-color: green;
        }
      }
    }
  }

  &Table {
    border-radius: 0.5rem;
    border: 0 none;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.08);
    height: 55vh;

    .rdg-header-row {
      .rdg-cell {
        background-color: var(--os-c-complement);
        color: var(--os-c-text);
        text-align: center;
      }
    }

    .rdg-row {
      .rdg-cell {
        color: var(--os-c-text);
        cursor: pointer;

        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &[aria-selected='true'] {
          box-shadow: inset 0 0 0 2px #cdcbc9;
        }

        &.colcenter {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &.colpercentage {
          color: #2818ff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &.colright {
          display: inline-flex;
          align-items: flex-end;
          justify-content: right;
        }
      }
    }
  }

  &Body {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 1rem 1rem;
    min-height: 50px;
    width: 100%;

    &Cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 21vw));
      grid-gap: 2rem;
    }

    @media (max-width: 1240px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    @media (max-width: 960px) {
      padding: 1rem 0 0;

      &Cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (max-width: 540px) {
      &Cards {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .chart-panel {
      padding: 20px 0;
      &__item {
        padding: 20px;
        border: 1px solid #e8e8e8;
        width: 55%;
        height: 580px;
        margin: auto;
      }
    }

    @media (max-width: 960px) {
      .chart-panel {
        padding: 20px 0;
        &__item {
          border: 1px solid #e8e8e8;
          width: 100%;
          height: 580px;
          margin: auto;
        }
      }
      .apexcharts-toolbar {
        display: none;
      }
      .apexcharts-title-text {
        transform: translateX(-105px);
      }
    }
  }

  &Pdf {
    width: 24px;
  }

  @media (max-width: 1240px) {
    &Search {
      max-width: 100%;
    }
  }
}

.buttonNew,
.buttonNew:hover {
  background: #4f81bd;
  border-color: #4f81bd;
}

.listEvidencia {
  overflow: auto;
  width: 100%;
  border: 1px solid #d8d8d8;
  padding: 0 10px;
  min-height: 50px;
  &__wrap {
    display: flex;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-end;
  }
  &__items {
    display: flex;
    max-width: 492px;
    width: 100%;
    gap: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    &:empty {
      display: none;
    }
  }
  &__item {
    position: relative;
  }
  &__wrapImage {
    margin: 0;
    width: 57px;
    height: 57px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: -6px;
    width: 9px;
    background: none;
    border: none;
    height: 9px;
    cursor: pointer;
    &:after,
    &:before {
      content: '';
      width: 9px;
      height: 2px;
      display: block;
      background-color: #ff0d0d;
      border-radius: 3px;
      position: absolute;
      inset: 0;
    }
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }
}
