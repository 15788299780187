.DetailView72 {
  &Body {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0.25rem;
    min-height: 50px;
    width: 100%;
    max-width: 960px;

    @media (max-width: 1240px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  &Data {
    width: 100%;

    .row-2,
    .row {
      margin-bottom: 1rem;

      &.none {
        margin-bottom: 0;
      }
    }

    .row-custom {
      .col:first-of-type {
        width: 33%;
        flex: inherit;
      }
      .col:last-of-type {
        width: 67%;
        flex: inherit;
      }
    }

    .row-checked {
      display: flex;
      justify-content: end;
      padding: 0 10px;
    }

    .row-buttons {
      .button-green {
        background: green;
        border-color: green;
        width: 160px;

        &:disabled {
          background: gray;
          border: gray;
        }
      }

      .button-purple {
        background: #6f6fdb;
        border-color: #6f6fdb;
        float: right;
        width: 160px;

        &:disabled {
          background: gray;
          border: gray;
        }
      }

      .button-red {
        background: #d50032;
        border-color: #d50032;
        float: right;
        width: 160px;

        &:disabled {
          background: gray;
          border: gray;
        }
      }

      .button-red-secondary {
        background: #d50032;
        border-color: #d50032;
        width: 230px;

        &:disabled {
          background: gray;
          border: gray;
        }
      }

      .button-purple-secondary {
        background: #6f6fdb;
        border-color: #6f6fdb;
        float: right;
        width: 230px;

        &:disabled {
          background: gray;
          border: gray;
        }
      }
    }

    .actions {
      justify-content: end;
      padding: 0 0.5rem;

      .button {
        width: 200px;
      }
    }

    .error {
      justify-content: start;
      text-align: left;
      padding: 0 0.5rem;
      font-size: 0.9rem;
    }

    .col {
      padding: 0 0.5rem;
      position: relative;

      button.button-icon {
        position: absolute;
        right: 10px;
        top: 32px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        img {
          filter: brightness(0) saturate(100%) invert(26%) sepia(89%) saturate(1812%) hue-rotate(91deg) brightness(96%) contrast(110%);
        }
      }

      &.first {
        padding-left: 0;
      }

      &.last {
        padding-right: 0;
      }
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ul {
      display: flex;
      border: 1px solid var(--os-c-gray);
      padding: 1.25rem 0.75rem 0.5rem;
      border-radius: 3px;
    }

    li {
      margin: 0 0.75rem 0.75rem 0;
      font-size: 0.9rem;
      cursor: pointer;
      border: 2px solid var(--os-c-secondary-light);

      &.no {
        cursor: none;
        padding: 0.5rem 0;
        border: 0 none;
      }

      img {
        line-height: 1;
        display: block;
        transition: transform 0.25s ease-in-out;
        transform: scale(1);

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;

      .row-2 {
        flex-direction: column;

        .col {
          width: 100%;
          padding: 0;
          margin: 0.5rem 0 0;
        }
      }

      .col .row-2 .col {
        margin-bottom: 1rem;

        &.last {
          margin-bottom: 0;
        }
      }
    }
  }

  &Table {
    border-radius: 0.5rem;
    border: 0 none;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.08);
    max-height: 55vh;
    height: auto;
    width: 98%;
    margin: 20px auto;
    height: 100%;
    max-height: 385px;

    .rdg-header-row {
      .rdg-cell {
        background-color: var(--os-c-complement);
        color: var(--os-c-text);
        text-align: center;
      }
    }

    .rdg-row {
      .rdg-cell {
        color: var(--os-c-text);
        cursor: pointer;

        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &[aria-selected='true'] {
          box-shadow: inset 0 0 0 2px #cdcbc9;
        }

        &.colcenter {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          outline: none;
          box-shadow: none;
        }

        &.colpercentage {
          color: #2818ff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &.colright {
          display: inline-flex;
          align-items: flex-end;
          justify-content: right;
        }
      }
    }
  }

  &Body {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 1rem 1rem;
    min-height: 50px;
    width: 100%;

    &Cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 21vw));
      grid-gap: 2rem;
    }

    @media (max-width: 1240px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    @media (max-width: 960px) {
      padding: 1rem 0 0;

      &Cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (max-width: 540px) {
      &Cards {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .chart-panel {
      padding: 20px 0;
      &__item {
        padding: 20px;
        border: 1px solid #e8e8e8;
        width: 55%;
        height: 580px;
        margin: auto;
      }
    }

    @media (max-width: 960px) {
      .chart-panel {
        padding: 20px 0;
        &__item {
          border: 1px solid #e8e8e8;
          width: 100%;
          height: 580px;
          margin: auto;
        }
      }
      .apexcharts-toolbar {
        display: none;
      }
      .apexcharts-title-text {
        transform: translateX(-105px);
      }
    }
  }
}

.react-datepicker__header--time {
  display: none;
}

.react-datepicker__time-list {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;

  li {
    border: none;
    margin: 0 !important;
  }
}

.tableActions {
  display: flex;
  svg {
    font-size: 20px;

    &.fa-circle-minus {
      color: #d50032;
    }
  }
}

.react-responsive-modal-modal {
  max-width: 950px !important;
  overflow: hidden;
  margin: 0 !important;
  padding: 1.2rem 0 !important;
}
.ContentInnerModal {
  width: 950px;
  padding: 0;
}

.modalAddPhotoTitle {
  margin-left: 30px;
}
.modalAddPhoto {
  width: 760px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__drag {
    background: none;
    width: 511px;
    height: 338px;
    border: 2px dashed black;
    border-radius: 4px;
    img {
      margin-bottom: 30px;
    }
    p {
      line-height: 19px;
      color: #515151;
      font-weight: bold;
      strong {
        color: #5186c5;
      }
    }
    span {
      color: #515151;
      font-weight: 300;
    }
  }
  &__wrapper {
    position: relative;
    margin-bottom: 60px;
  }
  &__loading {
    position: absolute;
    inset: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 2;
    font-size: 24px;
    &:after {
      content: '';
      z-index: -1;
      position: absolute;
      inset: 0;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}
.image-item {
  position: relative;
  width: 60px;
  height: 60px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__delete {
    border: none;
    background: none;
    width: 9px;
    height: 9px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    &:after,
    &:before {
      content: '';
      width: 12px;
      height: 3px;
      border-radius: 3px;
      background-color: #ff0d0d;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
    }
  }
  &__update {
    border: none;
    background-color: #d50032;
    border-radius: 4px;
    color: #f2f2f2;
    font-size: 12px;
    padding: 5px 15px;
    display: block;
    margin: auto;
  }
}
.addItem {
  &__open {
    border: none;
    background: none;
    color: white;
    width: 150px;
    height: 44px;
    border-radius: 4px;
    background-color: #4959b1;
    cursor: pointer;
  }
}

.ModalPhoto {
  z-index: 2222;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.nobutton {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.error {
  color: #d50032;
  font-size: 14px !important;
}

.icon path {
  fill: #5a9fb1;
}

.sigCanvas {
  border: 2px solid #333;
  width: 100%;
  max-width: 500px;
  height: 200px;
}

.sigCanvas-success {
  height: 100px;
  width: 100%;
  max-width: 500px;
  justify-content: start;
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 22px;
}

.button-gray {
  background: gray;
  border: gray;
  width: 160px;
  margin-left: 15px;
}

.button-gray:hover {
  background: #ebebeb;
}

.button-continue,
.button-continue:hover {
  background: #5186c5;
  border: #5186c5;
  width: 160px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
