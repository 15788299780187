.Spinner {
    position: relative;
    width: 100%;
    overflow: hidden;

    &Inner {
        display: flex;
        width: 100%;
        height: 12rem;
        align-items: center;
        justify-content: center;
        color: var(--os-c-primary);
    }
}
