html {
    font-size: 16px;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--os-f-family-alt);
    line-height: 1.6;
    font-size: 16px;
    color: var(--os-c-text);
}

* {
    box-sizing: border-box;
}

a {
    color: var(--os-c-primary-light);
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

img {
    max-width: 100%;
    display: inline-flex;
}

/* Scroll */
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 20px;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 0;
    background: var(--os-c-complement-dark);
    transition: color 0.2s ease;
}

::-webkit-scrollbar-track {
    background: var(--os-c-complement);
    border-radius: 0;
}

::selection {
    background: var(--os-c-primary);
    color: #fff;
}
